import {ExperienceTourRoutePath, ProductTestRoutePath, RoutePath} from "constants/enums/routePath";

interface DashboardMenuItem {
  untranslatedTitle: string,
  linkTo: string,
  key: string,
}

export const menuItems: DashboardMenuItem[] = [
  {
    untranslatedTitle: "Experience tour",
    linkTo           : ExperienceTourRoutePath.VideoChoices,
    key              : "experience-tour",
  }
];
