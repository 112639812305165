import React, { useEffect, useState } from "react";
import cx from "classnames";
import { Direction } from "constants/enums/direction";
import { ActivePlayer, OffPlayer } from "./Player.dependancies";
import "./Player.scss";

type PlayerProps = {
  className?: string,
  frequency?: number,
  soundPosition?: Direction,
  dbPreset: number,
  onChange?: (dB: number) => void,
  onPlay?: (status: boolean) => void,
}

/**
 * Player Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param frequency
 * @param soundPosition
 * @param onChange
 * @constructor
 * @return {React.FC<PlayerProps>}
 */
const Player: React.FC<PlayerProps> = ({ className, frequency, soundPosition, dbPreset, onChange, onPlay }) => {
  const [play, setPlay] = useState(false);

  const classes: string = cx(
    "player",
    className,
  );

  useEffect(() => {
    if (onPlay) onPlay(false);
    setPlay(false);
  }, [frequency, soundPosition]);

  function handleClick(flag: boolean) {
    if (onPlay) onPlay(flag);
    setPlay(flag);
  }

  return (
    <div className={classes}>
      {
        play
          ? <ActivePlayer frequency={frequency} soundPosition={soundPosition} dbPreset={dbPreset} onClick={handleClick} onChange={onChange} />
          : <OffPlayer onClick={handleClick} />
      }
    </div>
  );
};

export default Player;
