import React from "react";
import cx from "classnames";
import Button from "components/commons/Button/Button";
import { Direction } from "constants/enums/direction";
import { Shape } from "constants/enums/shape";
import { Size } from "constants/enums/size";
import { useMount } from "hooks/global/useMount";
import { useOscillation } from "hooks/specific/useOscillation";
import { ReactComponent as MinusIcon } from "assets/img/svg/minus_btn.svg";
import { ReactComponent as PauseIcon } from "assets/img/svg/pause_btn.svg";
import { ReactComponent as PlayIcon } from "assets/img/svg/play_btn.svg";
import { ReactComponent as PlusIcon } from "assets/img/svg/plus_btn.svg";


interface IActivePlayer {
  className?: string,
  frequency?: number,
  dbPreset: number,
  soundPosition?: Direction,
  onClick: (flag: boolean) => void,
  onChange?: (dB: number) => void,
}

export const minDb = -60
export const maxDb = 40
export const chunkBase = 10

export const ActivePlayer: React.FC<IActivePlayer> = ({
  className,
  frequency = 1000,
  soundPosition,
  dbPreset,
  onClick,
  onChange
}) => {
  const config = { // TODO: externalize this configuration to parent
    frequency: {
      preset: frequency,
      minDb: minDb,
      maxDb: maxDb
    },
    decibels: {
      preset: dbPreset,
      chunkBase: chunkBase
    },
    soundPosition: {
      preset: {
        both: !soundPosition,
        left: soundPosition === Direction.Left,
        right: soundPosition === Direction.Right,
      }
    }
  };
  const { decibels } = useOscillation(config);

  const classes = cx(
    "active-player",
    className,
  );

  /**
   * TODO: Wrap it in an unmount props
   */
  useMount(() => {
    if (onChange) onChange(config.decibels.preset);
  });

  /**
   * Handle minus bouton click
   */
  function _handleMinusClick() {
    const evaluated = decibels.getValue() - config.decibels.chunkBase
    if (evaluated < config.frequency.minDb) return

    decibels.down();
    if (onChange) onChange(decibels.getValue());
  }

  /**
   * Handle user click on Pause button
   * @param event
   */ // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function _handlePauseClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    onClick(false);
  }

  /**
   * Handle plus bouton click
   */
  function _handlePlusClick() {
    const evaluated = decibels.getValue() + config.decibels.chunkBase
    if (evaluated > config.frequency.maxDb) return

    decibels.up();
    if (onChange) onChange(decibels.getValue());
  }

  return (
    <div className={classes}>
      <Button shape={Shape.Circled} size={Size.Small} onClick={_handleMinusClick}><MinusIcon /></Button>
      <Button shape={Shape.Circled} size={Size.Large} onClick={_handlePauseClick}><PauseIcon /></Button>
      <Button shape={Shape.Circled} size={Size.Small} onClick={_handlePlusClick}><PlusIcon /></Button>
    </div>
  );
};

interface IOffPlayer {
  onClick: (flag: boolean) => void
}

export const OffPlayer: React.FC<IOffPlayer> = ({ onClick }) => {
  function _handleClick() {
    onClick(true);
  }

  return (
    <button onClick={_handleClick}><PlayIcon /></button>
  );
};
