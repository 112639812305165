import React from "react";
import cx from "classnames";
import Header from "components/commons/Header/Header";
import { useTitle } from "hooks/specific/useTitle";
import "./ViewWrapper.scss";
import { useLocation }                                            from "react-router-dom";
import {ExperienceTourRoutePath, RoutePath} from "../../../../constants/enums/routePath";

type ViewWrapperProps = {
  className?: string,
}

/**
 * ViewWrapper Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param children
 * @return {React.FC<ViewWrapperProps>}
 */
const ViewWrapper: React.FC<ViewWrapperProps> = ({ className, children }) => {

  const location = useLocation();
  const calculatedTitle = useTitle();
  const hasHeader = ![
    RoutePath.Dashboard, 
    RoutePath.HearingTestThanks, 
    RoutePath.ScheduleThanks, 
    ExperienceTourRoutePath.Video,
    "/product-test/headset/discovery/1", 
    "/product-test/headset/discovery/2", 
    "/product-test/headset/discovery/3"
  ]?.includes(location?.pathname);

  const classes: string = cx(
    "view-wrapper",
    { "view-wrapper--full-height": !hasHeader },
    className,
  );

  return (
    <div className={classes}>
      {hasHeader && <Header title={calculatedTitle} />}
      {
        // @ts-ignore
        React.Children.map(children, child => React.cloneElement(child, {
          // @ts-ignore
          ...child.props,
          className: cx(
            "view-wrapper__component",
          )
        }))
      }
    </div>
  );
};

export default ViewWrapper;
