import { RoutePath } from "constants/enums/routePath"
import { usePatientContext } from "contexts/global/patient"
import { useMemo } from "react"
import { useHistory } from "react-router-dom"

const hearingRedirectOnMatch = [
    RoutePath.HearingTestWelcome,
    RoutePath.HearingTestIntro,
    RoutePath.HearingTestSetupInstructions,
    RoutePath.HearingTestMeasurement,
    RoutePath.HearingTestAgeRange,
    RoutePath.HearingTestQuestions,
    RoutePath.HearingTestRecontact,
    RoutePath.HearingTestThanks,
]

const scheduleRedirectOnMatch = [
    RoutePath.ScheduleWelcome,
    RoutePath.ScheduleCalendar,
    RoutePath.ScheduleThanks,
]

export function usePatientRedirect(): (pathname: string) => void {
    const { patientState } = usePatientContext()
    const history = useHistory()

    const isPatientSignedIn = useMemo(() => {
        return Object
            .values(patientState)
            .every(value => value !== null)

    }, [patientState])


    return function (pathname: string) {
        if (isPatientSignedIn === true)
            return

        if (hearingRedirectOnMatch.includes(pathname)) {
            history.replace(RoutePath.HearingTestSignChoices)

        } else if (scheduleRedirectOnMatch.includes(pathname)) {
            history.replace(RoutePath.ScheduleSignChoices)

        }
    }
}
