import {useHistory, useLocation} from "react-router-dom";

type useRedirectReturns = (to?: string) => void;

/**
 * Custom Hook
 * Allows to redirect to a route with by default the origin (from) in the history state
 * @param from
 * @param to
 */
export function useRedirect(from?: string, to?: string): useRedirectReturns {
  const {pathname} = useLocation();
  const history    = useHistory();
  const _to        = to; // Provide a clean parameter names on each function (hook and returned function)

  function redirect(to?: string) {
    const calculatedTo = to || _to;
    if (!calculatedTo) throw new Error("useRedirect - one parameter is missing. Paramater \"to\" is required ");
    history.push(calculatedTo, {from: from || pathname});
  }

  return redirect;
}
