import React from "react";
import cx from "classnames";
import Video from "../../../../components/commons/Video/Video";
import { useHistory, useLocation } from "react-router-dom";

type ExperienceTourVideoProps = {
  className?: string;
};

/**
 * ExperienceTourVideo Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @return {React.FC<ExperienceTourVideoProps>}
 */
const ExperienceTourVideo: React.FC<ExperienceTourVideoProps> = ({
  className,
}) => {
  const classes: string = cx("experience-tour-video", className);

  const history = useHistory();
  const location = useLocation();
  function handleVideoClose() {
    history.goBack();
  }

  return (
    <div className={classes}>
      <Video
        volume={location.state.volume}
        onClose={handleVideoClose}
        options={{
          controls: true,
          responsive: true,
          loop: true,
          fluid: true,
          sources: [
            {
              src: "https://sonoly.s3.eu-west-3.amazonaws.com/public/assets/video/ear_tour_paysage_experience.mp4",
              type: "video/mp4",
            },
          ],
        }}
      />
    </div>
  );
};

export default ExperienceTourVideo;
