import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import cx from "classnames";
import Typography from "components/commons/Typography/Typography";
import HookFormControl from "components/containers/Forms/HookFormControl/HookFormControl";
import Button from "components/commons/Button/Button";
import { LocaleNamespace, MonthLabels } from "config/intl/helpers";
import { RoutePath } from "constants/enums/routePath";
import { Size } from "constants/enums/size";
import { Typography as TypographyType } from "constants/enums/typography";
import { useRedirect } from "hooks/specific/useRedirect";
import { FormDataKey, FormDataValues } from "./ScheduleSignUp.dependencies";
import API from "services/api/index";
import { usePatientContext } from "contexts/global/patient";
import { setPatient } from "contexts/global/patient/actions";
import { useCatchApiErrors } from "hooks/specific/useCatchApiErrors";
import { DatePicker } from "antd-mobile";
import "./ScheduleSignUp.scss";

type ScheduleSignUpProps = {
  className?: string;
};

type FormValues = {
  lastname: string;
  firstname: string;
  email: string;
  address: string;
  city: string;
  postal_code: string;
  gender: string;
  birthdate: string;
};


const ScheduleSignUp: React.FC<ScheduleSignUpProps> = ({ className }) => {
  const classes: string = cx("schedule-sign-up", className);
  const { patientDispatch } = usePatientContext();
  const redirect = useRedirect(RoutePath.ScheduleSignUp);
  const apiErrorHandler = useCatchApiErrors();
  const { t, ready } = useTranslation<LocaleNamespace>(LocaleNamespace.Pages);
  const memoizedFormData = useMemo(() => FormDataValues(t), [t]);
  const form = useForm<FormValues>({ criteriaMode: "all", });
  const [pickerVisible, setPickerVisible] = useState(false)
  const [datePickerValue, setDatePickerValue] = useState(new Date(1950, 0, 1))

  const onSubmit = async (data: FormValues) => {
    const patients = await API.getPatients({ omnisearch: data.email });

    if (patients.length > 0) {
      toast.error(
        t(`${LocaleNamespace.Errors}:api.patients.already_exists`, { email: data.email }),
        { autoClose: 2000 }
      );

      return;
    }

    try {            
      const patient = await API.postPatients({
        firstName: data.firstname,
        lastName: data.lastname,
        gender: data.gender,
        adress: data.address,
        city: data.city,
        email: data.email,
        cpo: data.postal_code,
        birthDate: data.birthdate.split("/").reverse().join('-')
      });

      patientDispatch(setPatient(patient));
      redirect(RoutePath.ScheduleWelcome);

    }
    catch (error: any) {
      apiErrorHandler(error);
    }
  };

  return (
    <div className={classes}>
      {ready && (
        <form className={"schedule-sign-up__form"} onSubmit={form.handleSubmit(onSubmit)}>
          <Typography as={TypographyType.Subtitle} className="schedule-sign-up__subtitle">
            {t("schedule.sign-up.title")}
          </Typography>
          {memoizedFormData.map((formDataValue) => (
            <HookFormControl
              key={formDataValue.name}
              data={formDataValue}
              handleRegister={form.register}
              errors={form.formState.errors}
            />
          ))}
          <DatePicker
            style={{
              '--title-font-size': '1.5rem',
              '--header-button-font-size': '1.5rem',
              '--item-font-size': '1.5rem',
            }}
            visible={pickerVisible}
            onClose={() => setPickerVisible(false)}
            title="Date de naissance"
            min={new Date(1900, 0, 1)}
            renderLabel={(type, data) => type === "month" ? MonthLabels[data] : data}
            max={new Date()}
            value={datePickerValue}
            onSelect={(date) => {
              form.setValue("birthdate", date.toLocaleDateString('fr-FR'))
              setDatePickerValue(date)
            }}
            children={() => (
              <HookFormControl
                data={{
                  name: FormDataKey.Birthdate,
                  type: "button",
                  onClick: () => setPickerVisible(true),
                  label: t("schedule.sign-up.inputs.birthdate.label"),
                  required: {
                    value: true,
                    message: t(`${LocaleNamespace.Errors}:form.input.required`)
                  }
                }}
                handleRegister={form.register}
                errors={form.formState.errors}
              />
            )}
            onConfirm={(date) => {
              form.setValue("birthdate", date.toLocaleDateString('fr-Fr'))
            }}
          />
          <div className={"schedule-sign-up__gender-container"}>
            <p>{t("schedule.sign-up.inputs.gender.label")}</p>
            <HookFormControl
              data={{
                name: FormDataKey.Gender,
                id: FormDataKey.Gender + "-1",
                type: "radio",
                value: "HOMME",
                label: "Homme",
                defaultChecked: true
              }}
              handleRegister={form.register}
              errors={form.formState.errors}
            />

            <HookFormControl
              data={{
                name: FormDataKey.Gender,
                id: FormDataKey.Gender + "-2",
                type: "radio",
                value: "FEMME",
                label: "Femme",
              }}
              handleRegister={form.register}
              errors={form.formState.errors}
            />
          </div>
          <Button type={"submit"} size={Size.Small}>{t(`${LocaleNamespace.Common}:confirm`)}</Button>
        </form>
      )}
    </div>
  );
};

export default ScheduleSignUp;
