import React from "react";
import cx from "classnames";
import Video from "../../../../components/commons/Video/Video";
import { useHistory, useParams, useLocation } from "react-router-dom";

type HeadsetVideoProps = {
  className?: string;
};

/**
 * HeadsetVideo Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @return {React.FC<HeadsetVideoProps>}
 */
const HeadsetVideo: React.FC<HeadsetVideoProps> = ({ className }) => {
  const { id } = useParams<any>();
  const history = useHistory();
  const location = useLocation();

  const videoPaths: { [key: number]: string } = {
    1: "https://sonoly.s3.eu-west-3.amazonaws.com/public/assets/video/sonoly-presentation.mp4",
    2: "https://sonoly.s3.eu-west-3.amazonaws.com/public/assets/video/rolling_stones-paint_it_black.mp4",
    3: "https://sonoly.s3.eu-west-3.amazonaws.com/public/assets/video/celine_dion-my_heart_wiil_go_on.mp4",
  };

  const classes: string = cx("headset-video", className);

  function handleVideoClose() {
    history.goBack();
  }

  return (
    <div className={classes}>
      <Video
        volume={location.state.volume}
        onClose={handleVideoClose}
        options={{
          controls: true,
          responsive: false,
          fluid: true,
          sources: [
            {
              src: videoPaths[id],
              type: "video/mp4",
            },
          ],
        }}
      />
    </div>
  );
};

export default HeadsetVideo;
