import { Laboratory, LaboratoryAttendance, Patient, Schedule } from "@audiowizard/common"
import NetworkException from "./exceptions/NetworkException"
import UnauthorizedException from "./exceptions/UnauthorizedException"
import UnknownException from "./exceptions/UnknownException"
import UnprocessableEntityException from "./exceptions/UnprocessableEntityException"

/**
 * Responses's data (who's not in @audiowizard/common package) 
 */
export interface IPostLoginCheckData {
    token: string;
    refresh_token: string;
    userId: number;
}
export interface IPostRefreshTokenData {
    token: string;
    refresh_token: string;
}
export interface IViolation {
    propertyPath: string;
    message: string;
    code: string;
}
export interface IGetLaboratoriesAvailableSlotsData {
    availableSlots: {
        start: string,
        end: string
    }[]
}
/**
 * TODO: generate from @audiowizard/common
 */
export interface IPostHearingTestsData {
    leftEar: {
        tonalHertzFrequency1000: number
        tonalHertzFrequency2000: number
        tonalHertzFrequency6000: number
    }
    rightEar: {
        tonalHertzFrequency1000: number
        tonalHertzFrequency2000: number
        tonalHertzFrequency6000: number
    }
    sensations: {
        global                      : number,
        mumbling                    : number,
        phone                       : number,
        highPitchedSound            : number,
        noisyEnvironmentConversation: number,
    }
    minAgeRange: number,
    maxAgeRange: number,
    patient    : string
}

/**
 * Requests's payloads
 */
export interface IPostLoginCheckPayload {
    username: string;
    password: string;
}
export interface IPostPatientsPayload {
    firstName: string
    lastName: string
    gender: string
    adress: string
    city: string
    email: string
    cpo: string
    birthDate: string
}
export interface IGetPatientsQueryParams {
    omnisearch?: string /** not exhaustive, read api scheme to get more */
    [key: string]: any /** can generate api response error if key not exists */
}
export interface IGetLaboratoriesAvailableSlotsPayload {
    date: string
    laboratory: string
    scheduleStatus: string
}
export interface IPostSchedulesPayload {
    dateOf: string
    dateEnd: string
    state: string
    status: string
    laboratory: string
    user: string
    patient: string
    preScheduleNote?: string
}
export interface IGetLaboratoryAttendanceQueryParams {
    user: string
}
export interface IPostHearingTestsPayload {
    leftEar: {
        tonalHertzFrequency1000: number
        tonalHertzFrequency2000: number
        tonalHertzFrequency6000: number
    }
    rightEar: {
        tonalHertzFrequency1000: number
        tonalHertzFrequency2000: number
        tonalHertzFrequency6000: number
    }
    sensations: {
        global                      : number,
        mumbling                    : number,
        phone                       : number,
        highPitchedSound            : number,
        noisyEnvironmentConversation: number,
    }
    minAgeRange: number,
    maxAgeRange: number,
    patient    : string

}
export interface IPostRefreshTokenPayload {
    refresh_token: string
}

/**
 * Requests's functions
 */
export type TPostLoginCheckFunc = (payload: IPostLoginCheckPayload) => Promise<IPostLoginCheckData>
export type TPostPatientsFunc = (payload: IPostPatientsPayload) => Promise<Patient>
export type TGetPatientsFunc = (filters?: IGetPatientsQueryParams) => Promise<Patient[]>
export type TGetLaboratoriesFunc = () => Promise<Laboratory[]>
export type TGetLaboratoriesAvailableSlotsFunc = (payload: IGetLaboratoriesAvailableSlotsPayload) => Promise<IGetLaboratoriesAvailableSlotsData>
export type TPostShedulesFunc = (payload: IPostSchedulesPayload) => Promise<Schedule>
export type TPostHearingTestsFunc = (payload: IPostHearingTestsPayload) => Promise<IPostHearingTestsData>
export type TGetLaboratoryAttendanceFunc = (filters: IGetLaboratoryAttendanceQueryParams) => Promise<LaboratoryAttendance[]>
export type TPostRefreshTokenFunc = (payload: IPostRefreshTokenPayload) => Promise<IPostRefreshTokenData>

/**
 * Routes's functions
 */
export type TValidatePathFunc = (routePath: string) => void
export type TBuildPathFunc = (routePath: string, routeParams: { [key: string]: any }) => string
export type TBuildQueryParamsFunc = (routePath: string, queryParams: { [key: string]: any }) => string

/**
 * Exceptions
 */
export type TupleExceptions = [
    InstanceType<typeof NetworkException>,
    InstanceType<typeof UnauthorizedException>,
    InstanceType<typeof UnprocessableEntityException>,
    InstanceType<typeof UnknownException>,
]

export type Exception = TupleExceptions[number]
/**
 * Server HTTP status
 */
export enum EnumResponseHttpStatus {
    UnprocessableEntity = 422,
    Unauthorized = 401,
}