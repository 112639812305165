import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { ReactComponent as CheckIcon } from "assets/img/svg/check_star.svg";
import Button from "components/commons/Button/Button";
import Typography from "components/commons/Typography/Typography";
import { LocaleNamespace } from "config/intl/helpers";
import { Typography as TypographyType } from "constants/enums/typography";
import { Size } from "../../../../constants/enums/size";
import { useRedirect } from "../../../../hooks/specific/useRedirect";
import { RoutePath } from "../../../../constants/enums/routePath";
import { useHearingTest } from "contexts/hearingTest/HearingTestContext";
import API from "services/api";
import { usePatientContext } from "contexts/global/patient";
import routes, { buildPath } from "services/api/config/routes";
import { resetHearingTest } from "contexts/hearingTest/actions";
import { useCatchApiErrors } from "hooks/specific/useCatchApiErrors";
import "./Thanks.scss";
import ZAPIER from "services/zapier";
import TokenService from "services/token";

type ThanksProps = {
  className?: string,
}

/**
 * Thanks Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<ThanksProps>}
 */
const Thanks: React.FC<ThanksProps> = ({ className }) => {
  const { t } = useTranslation(LocaleNamespace.Pages);
  const redirect = useRedirect(RoutePath.HearingTestThanks);
  const [htState, htDispatch] = useHearingTest();
  const { patientState } = usePatientContext()
  const apiErrorHandler = useCatchApiErrors()

  const classes: string = cx(
    "hearing-test-thanks",
    className,
  );

  function handleClick() {
    redirect(RoutePath.Dashboard);
  }

  useEffect(() => {
    if (Object.values(htState.hearingSensations).some(v => v === null)) {
      return redirect(RoutePath.Dashboard);
    }

    const { hearingSensations, tonalAudiometryResults } = htState;

    const payload = {
      leftEar: {
        tonalHertzFrequency1000: tonalAudiometryResults.leftEar["1000"],
        tonalHertzFrequency2000: tonalAudiometryResults.leftEar["2000"],
        tonalHertzFrequency6000: tonalAudiometryResults.leftEar["4000"]
      },
      rightEar: {
        tonalHertzFrequency1000: tonalAudiometryResults.rightEar["1000"],
        tonalHertzFrequency2000: tonalAudiometryResults.rightEar["2000"],
        tonalHertzFrequency6000: tonalAudiometryResults.rightEar["4000"]
      },
      sensations: hearingSensations,
      minAgeRange: 0,
      maxAgeRange: 0,
      patient: buildPath(routes.patient, { id: patientState.id })
    };

    API.postHearingTests(payload)
      .then(() => ZAPIER.sendHearingTestEmail({ 
        hearingTest: htState, 
        patient: patientState,
        user: { email: TokenService.getUserEmail()}
      }))
      .catch(error => apiErrorHandler(error))

    htDispatch(resetHearingTest)
  }, [])

  return (
    <div className={classes}>
      {/* TODO: fix for the correct device view */}
      <div className="hearing-test-thanks__content">
        <CheckIcon className="hearing-test-thanks__check-icon" />
        <Typography as={TypographyType.Title}
          className="hearing-test-thanks__title">Merci d’avoir réalisé <br /> le test SONOLY</Typography>
        <Button size={Size.Small} onClick={handleClick}>{t("hearing-test.thanks.btn")}</Button>
        <Typography as={TypographyType.Body} className="hearing-test-thanks__text">{t("hearing-test.thanks.caption")}</Typography>
      </div>
    </div>
  );
};

export default Thanks;
